<template>
  <v-expansion-panels
    v-if="message.textTechDetails"
    elevation="1"
    class="o-message-tech-details"
  >
    <v-expansion-panel>
      <v-expansion-panel-title>
        <template v-slot:default="{ }">
          <v-row no-gutters>
            <v-col class="d-flex justify-start font-bold" cols="12">
              Tech details
            </v-col>
          </v-row>
        </template>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-md-editor v-if="message.textTechDetails" v-model="textTechDetails" mode="preview" height="auto" class="md-editor"></v-md-editor>
      </v-expansion-panel-text>
    </v-expansion-panel>
    <v-progress-linear
      v-if="!message.textSummary && message.isLoading"
      height="2"
      indeterminate
    ></v-progress-linear>
  </v-expansion-panels>
</template>

<script setup>
import { computed, defineProps } from 'vue';

const props = defineProps({
  message: {
    type: Object,
    required: true
  }
});

const textTechDetails = computed({
  get: () => props.message.textTechDetails,
})
</script>

<style scope>
.o-message-tech-details {
  .github-markdown-body {
    padding: 0;
    margin: 0;
    font-size: 14px;
  }
}
</style>