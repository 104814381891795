<template>
  <div
    @mouseover="onHover"
    @mouseleave="onHoverLeave"
    class="a-icon-thumbs"
  >
    <!-- <i :class="iconClass" @click="$emit('click', direction)"></i> -->
    <v-icon
      :icon="iconClass"
      @click="$emit('click', direction)"
    ></v-icon>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, ref } from 'vue';

const props = defineProps({
  direction: {
    type: String,
    default: 'up'
  },
  selected: {
    type: Boolean,
    default: false
  }
});
defineEmits(['click']);

const thumbsIconDirection = `mdi-thumb-${props.direction}`;
const getIconClass = (isFilled = false) => {
  const iconClass = `${thumbsIconDirection}-outline`
  if (props.selected || isFilled) {
    return thumbsIconDirection;
  }
  return iconClass;
}

const iconClass = ref(getIconClass());

const onHover = () => {
  iconClass.value = getIconClass(true);
}
const onHoverLeave = () => {
  iconClass.value = getIconClass();
}
</script>

<style lang="scss">
.a-icon-thumbs {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 1.2rem;
  
  cursor: pointer;
}
</style>