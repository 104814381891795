<template>
  <div
    v-click-outside="handleClickOutside"
    class="o-data-viewer-table"
  >
    <div class="data-viewer-table__header">
      <div class="data-viewer-table__header-left">
        <div
          v-if="!dataItem.isDataAppPreview"
          class="data-viewer-table__title"
        >
          {{ dataItem.title }}
        </div>
        <!-- <div
          v-if="isDataPreviewTab"
          class="data-viewer-table__header-actions"
        >
          <ODataViewerTableTab
            title="Filter Rows"
            :count="countFilterItems"
            @click="handleShowFilter"
          />
          <ODataViewerTableTab
            title="Sort Rows"
            :count="countSortItems"
            @click="handleShowSort"
          />
        </div> -->
      </div>
      <div
        class="data-viewer-table__header-download"
        @click="downloadCSVAsset()"
      >
        <v-hover>
          <template v-slot:default="{ isHovering, props }">
            <v-icon
              icon="mdi-download"
              v-bind="props"
              :color="isHovering ? 'black' : '#757575'"
            ></v-icon>
          </template>
        </v-hover>
        <div class="data-viewer-table__header-title">
          Download CSV
        </div>
      </div>
    </div>
    <OGridPreviewData
      v-if="isDataPreviewTab"
      grid-key="grid-output-preview-data"
      :headers="tableConfig.headers"
      :data-items="tableConfig.dataItems"
      :is-loading="brickConnectors[dataItem.key].isLoading"
      :page="tableConfig.page"
      :column-types="tableConfig.columnTypes"
      :total="brickConnectors[dataItem.key].currentRows"
      :no-records-message="MESSAGES.FULL_DATA_ALERT"
      :is-cell-preview="true"
      :is-cell-selectable="true"
      @[EVENT_PAGE_CHANGE]="pageChange"
      @[EVENT_SORT_CHANGE]="handleSortColumn"
      @[EVENT_CELL_COPY]="handleCopyCell"
      @[EVENT_CELL_SELECT]="handleSelectCell"
      @[EVENT_CELL_PREVIEW]="handleShowPreviewCell"
    />
    <!-- :sort-by.sync="brickConnectors[dataItem.key].sortByColumn"
    :sort-desc.sync="brickConnectors[dataItem.key].sortDescColumn" -->
    <OGrid
      v-else
      grid-key="grid-output-preview-data-summary"
      :headers="tableConfig.headers"
      :data-items="tableConfig.dataItems"
      :is-loading="brickConnectors[dataItem.key].isLoading"
      :total="brickConnectors[dataItem.key].currentRows"
      :is-hide-footer="true"
      :is-header-uppercase="false"
      :no-records-message="MESSAGES.SUMMARY_ALERT"
    />
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import {
  ref,
  watch,
  inject,
  computed,
  onBeforeMount,
  defineComponent,
  onMounted,
  onUnmounted,
} from 'vue'

import OGrid from '@/shared/components/o-grid'
import OGridPreviewData from '@/shared/components/o-grid-preview-data'
import ODataViewerTableTab from '../o-data-viewer-table-tab'

import {
  outputPreviewLoad,
  outputPreviewPage,
  outputPreviewPageCell,
} from '@/shared/api/task-manager.api'

import {
  useBus,
  // useModal,
  useErrorHandler,
  useCopyToClipboard,
  useTransformDataset,
} from '@/shared/composables'
// import {
//   useAlerts,
//   useCurrentGroup,
//   useCurrentProjectPipeline,
// } from '@/store/use'
import { randomString } from '@/shared/utils/randomString'
import useDataViewer from '../use-data-viewer'
import useFetchHeavyTask from '@/shared/composables/use-fetch-heavy-task'
import { useDataViewerTable } from './use-data-viewer-table'

import {
  TABS_MAP,
  MESSAGES,
  DEFAULT_COUNT_CELL_SYMBOLS,
} from '@/shared/constants/data-viewer-constants'
import { taskStatuses } from '@/shared/constants/task-statuses-constants'
import {
  SORT_DIRECTIONS,
} from '@/shared/constants/grid-constants'
import {
  EVENT_CELL_COPY,
  EVENT_CELL_SELECT,
  EVENT_CELL_PREVIEW,
  EVENT_SORT_CHANGE,
  EVENT_PAGE_CHANGE,
} from '@/shared/constants/bus-events-constants'
import { useDownloadFile } from '@/shared/composables/use-download-file'

export default defineComponent({
  name: 'ODataViewerTable',
  components: {
    OGrid,
    OGridPreviewData,
    // ODataViewerTableTab,
  },
  props: {
    grid: {
      type: String,
      default: () => TABS_MAP.DATA_PREVIEW,
    },
    dataItem: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore()
    const route = useRoute()
    // const { openModal } = useModal()
    const { busOn, busOff } = useBus()
    // const { pushErrorSnackbar, pushDefaultSnackbar, pushCustomSnackbar } = store.alerts
    const { fetchHeavyTask } = useFetchHeavyTask()
    const { errorHandlerAlert } = useErrorHandler()
    const { copyToClipboard } = useCopyToClipboard()
    const { connectorConfigSample } = useDataViewer()
    const { downloadFile } = useDownloadFile()
    const {
      isDataPreviewTab,
      gridDataRequestParams,
    } = useDataViewerTable(props)
    // const { currentGroupId } = useCurrentGroup()
    // const { selectedBrick, currentProjectPipelineId } = useCurrentProjectPipeline()
    const {
      getTempCsvPath,
      prepareDataTransformPklToCsv,
      prepareDataTransformDataset,
    } = useTransformDataset({ props })

    const apiKeyUuid = route.query.apiKeyUuid || props.dataItem.apiKeyUuid

    const selectedCell = ref(null)
    const brickConnectors = inject('brickConnectors', ref({}))
    const tableConfig = ref({
      page: 1,
      rows: 10,
      headers: [],
      dataItems: [],
      columnTypes: {},
    })

    const countFilterItems = computed(() => props.dataItem.filterProperties?.filters?.length)
    const countSortItems = computed(() => props.dataItem.sort_by?.length)
    const rawColumns = ref([])

    const prepareGridData = (data = {}) => {
      const prepareGridDataItem = (el) => ({
        value: el.name,
        title: el.name,
        sortable: isDataPreviewTab.value,
        sortBy: el.name,
        sortDesc: null,
        minWidth: '80',
        width: null,
      })
      rawColumns.value = data.payload?.schema?.fields || []
      tableConfig.value.headers = rawColumns.value.map(prepareGridDataItem)

      if (tableConfig.value.headers.length && !isDataPreviewTab.value) {
        tableConfig.value.headers[0].style = {
          background: '#F7F7F7',
        }
        tableConfig.value.headers[0].minWidth = '120'
        tableConfig.value.headers[0].width = null
      }

      tableConfig.value.dataItems = data.payload?.data || []
      const colTypes = data.payload?.schema?.fields || []
      tableConfig.value.columnTypes = colTypes.reduce((types, col) => {
        types[col.name] = {
          name: col.name,
          type: col.type,
          datetime_format: col.datetime_format || '',
        }
        return types
      }, {})
      if (isDataPreviewTab.value) {
        brickConnectors.value[props.dataItem.key].totalRows = data.total_rows
        brickConnectors.value[props.dataItem.key].columnTypes = tableConfig.value.columnTypes
        brickConnectors.value[props.dataItem.key].currentRows = data.current_rows
      }

      if (brickConnectors.value[props.dataItem.key].limit > data.total_rows) {
        // TODO: Need to have load uuid of preview to use this functionality
        // brickConnectors.value[props.dataItem.key].limit = data.total_rows
      }
    }

    const resetPreviewToSampleOnError = (isReload) => {
      tableConfig.value.page = 1
      const dataItem = brickConnectors.value[props.dataItem.key]
      brickConnectors.value[props.dataItem.key] = connectorConfigSample(dataItem, isReload)
    }

    const handleGetPage = async () => {
      brickConnectors.value[props.dataItem.key].isLoading = true
      const filterProps = isDataPreviewTab.value ? {
        filter: props.dataItem.filter,
        sort_by: props.dataItem.sort_by,
      } : {}
      try {
        const { data } = await outputPreviewPage({
          groupId: props.dataItem.groupId,
          // pipelineId: currentProjectPipelineId.value,
          apiKeyUuid,
          ...gridDataRequestParams.value,
          page: tableConfig.value.page,
          rows_per_page: tableConfig.value.rows,
          ...filterProps,
        })
        prepareGridData(data)
      } catch (error) {
        // errorHandlerAlert(error, pushErrorSnackbar)
        resetPreviewToSampleOnError(false)
      } finally {
        brickConnectors.value[props.dataItem.key].isLoading = false
      }
    }

    const getPageCellText = async (cell) => {
      try {
        const filterProps = isDataPreviewTab.value ? {
          filter: props.dataItem.filter,
          sort_by: props.dataItem.sort_by,
        } : {}
        const { data } = await outputPreviewPageCell({
          groupId: props.dataItem.groupId,
          // pipelineId: currentProjectPipelineId.value,
          apiKeyUuid,
          ...gridDataRequestParams.value,
          page: tableConfig.value.page,
          rows_per_page: tableConfig.value.rows,
          ...filterProps,
          cell_column: cell.column,
          cell_row: cell.row,
        })
        return data.value
      } catch (error) {
        // errorHandlerAlert(error, pushErrorSnackbar)
      }
    }

    const handleGetPageCell = async (cell) => {
      brickConnectors.value[props.dataItem.key].isLoading = true
      try {
        let cellText = cell.text
        if (cell.text.length === DEFAULT_COUNT_CELL_SYMBOLS) {
          cellText = await getPageCellText(cell)
        }
        return cellText
      } catch (error) {
        // errorHandlerAlert(error, pushErrorSnackbar)
      } finally {
        brickConnectors.value[props.dataItem.key].isLoading = false
      }
    }

    const handleShowPreviewCell = async (cell) => {
      const data = await handleGetPageCell(cell)
      const limitLen = 30
      const columnName = cell.column.length >= limitLen ? `${cell.column.substring(0, limitLen)}...` : cell.column
      const title = `column ${columnName}, row ${cell.row + 1}`
      selectedCell.value = null
      // openModal.previewText({ data, title })
    }

    const handleCopyCell = async (cell) => {
      const data = await handleGetPageCell(cell)
      copyToClipboard(data)
    }

    const handleSelectCell = (cell) => {
      selectedCell.value = cell
    }

    const handleCopyCellByKeydown = async (event) => {
      if (!selectedCell.value) {
        return
      }

      if (event.ctrlKey && event.code === 'KeyC') {
        event.preventDefault()
        await handleCopyCell(selectedCell.value)
      }
      if (event.metaKey && !event.shiftKey && event.code === 'KeyC') {
        event.preventDefault()
        await handleCopyCell(selectedCell.value)
      }
    }

    const loadPreview = async () => {
      brickConnectors.value[props.dataItem.key].isLoading = true
      const res = await fetchHeavyTask(outputPreviewLoad, {
        // groupId: currentGroupId.value,
        // pipelineId: currentProjectPipelineId.value,
        apiKeyUuid,
        ...gridDataRequestParams.value,
      })

      if (res.status === taskStatuses.FAILURE) {
        // pushErrorSnackbar(res.message)
        resetPreviewToSampleOnError(brickConnectors.value[props.dataItem.key].isFullDataset)
        brickConnectors.value[props.dataItem.key].isLoading = false
      } else {
        await handleGetPage()
      }
      if (res.status !== taskStatuses.FAILURE && typeof res.message === 'string') {
        // pushDefaultSnackbar(res.message)
      }
    }

    const pageChange = (event) => {
      tableConfig.value.page = event.page
      tableConfig.value.rows = event.rows
    }

    const handleShowFilter = () => {
      const listenerEvent = `FILTER_MODAL_${randomString()}`
      busOn(listenerEvent, (data) => {
        tableConfig.value.page = 1
        brickConnectors.value[props.dataItem.key] = { ...props.dataItem, ...data }
      })
      const onCloseModal = () => { busOff(listenerEvent) }
      // openModal.dataPreviewFilter({
      //   data: { ...props.dataItem, columns: rawColumns.value.map((el) => el.name) },
      //   listenerEvent,
      // }, { onCloseModal })
    }

    const handleShowSort = () => {
      const listenerEvent = `SORT_MODAL_${randomString()}`
      busOn(listenerEvent, (data) => {
        brickConnectors.value[props.dataItem.key] = { ...props.dataItem, ...data }
      })
      const onCloseModal = () => { busOff(listenerEvent) }
      // openModal.dataPreviewSort({
      //   data: { ...props.dataItem, columns: rawColumns.value.map((el) => el.name) },
      //   listenerEvent,
      // }, { onCloseModal })
    }

    function resetSortedColumn(connectorKey) {
      brickConnectors.value[connectorKey].sortByColumn = null
      brickConnectors.value[connectorKey].sortDescColumn = false
    }

    function resetAllSortedColumns() {
      const connectorKeys = Object.keys(brickConnectors.value)
      connectorKeys.forEach(key => {
        if (brickConnectors.value[props.dataItem.key].key !== key) {
          resetSortedColumn(key)
        }
      })
    }

    function handleSortColumn(value) {
      resetAllSortedColumns()
      brickConnectors.value[props.dataItem.key].sort_by = [{
        column_id: value.sortBy,
        direction: value.sortDesc ? SORT_DIRECTIONS.DESC : SORT_DIRECTIONS.ASC,
      }]
      brickConnectors.value[props.dataItem.key].sortByColumn = value.sortBy
      brickConnectors.value[props.dataItem.key].sortDescColumn = value.sortDesc
      handleGetPage()
    }

    function handleTransformAndDownloadDataset(fileType) {
      const data = prepareDataTransformDataset({
        sourcePath: props.dataItem.path,
        sourceType: fileType,
        targetPath: getTempCsvPath(),
        targetType: 'csv',
      })
      // pushCustomSnackbar({
      //   isManuallyExpiring: true,
      //   componentName: 'o-snackbar-download-asset',
      //   data,
      // })
    }

    async function handleDownloadPkl() {
      const persistedDataSetPath = props.dataItem.persisted_data_set_path
      const data = await prepareDataTransformPklToCsv({
        path: props.dataItem.path,
        persistedDataSetPath,
      })
      // pushCustomSnackbar({
      //   isManuallyExpiring: true,
      //   componentName: 'o-snackbar-download-asset',
      //   data,
      // })
    }

    const downloadCSVAsset = () => {
      const fileType = props.dataItem.path.split('.').pop().toLowerCase()
      if (fileType === 'parquet') {
        handleTransformAndDownloadDataset(fileType)
      } else if (fileType === 'pkl') {
        handleDownloadPkl()
      } else if (fileType === 'csv') {
        downloadFile({ path: props.dataItem.path, apiKeyUuid })
      } else {
        // pushErrorSnackbar(`Can't download. Origin file has unsupported type.`)
      }
    }

    function handleClickOutside() {
      if (selectedCell.value !== null) {
        selectedCell.value = null
      }
    }

    watch(() => tableConfig.value.page, (value, oldValue) => {
      if (value !== oldValue && !brickConnectors.value[props.dataItem.key].isLoading) {
        handleGetPage()
      }
    })

    watch(() => tableConfig.value.rows, (value, oldValue) => {
      if (value !== oldValue && !brickConnectors.value[props.dataItem.key].isLoading) {
        handleGetPage()
      }
    })

    watch(() => props.dataItem.reloadId, (value, oldValue) => {
      if (value !== oldValue && !brickConnectors.value[props.dataItem.key].isLoading) {
        loadPreview()
      }
    })
    watch(() => props.dataItem.sortId, (value, oldValue) => {
      if (value !== oldValue && !brickConnectors.value[props.dataItem.key].isLoading) {
        resetSortedColumn(brickConnectors.value[props.dataItem.key].key)
      }
    })

    onBeforeMount(() => {
      loadPreview()
    })

    onMounted(() => {
      document.addEventListener('keydown', handleCopyCellByKeydown)
    })
    onUnmounted(() => {
      document.removeEventListener('keydown', handleCopyCellByKeydown)
    })

    return {
      MESSAGES,
      tableConfig,
      countSortItems,
      brickConnectors,
      isDataPreviewTab,
      countFilterItems,
      EVENT_CELL_COPY,
      EVENT_CELL_SELECT,
      EVENT_CELL_PREVIEW,
      EVENT_SORT_CHANGE,
      EVENT_PAGE_CHANGE,
      downloadCSVAsset,
      pageChange,
      handleCopyCell,
      handleShowSort,
      handleSortColumn,
      handleSelectCell,
      handleShowFilter,
      handleShowPreviewCell,
      handleClickOutside,
    }
  },
})
</script>

<style lang="scss">
@import "o-data-viewer-table";
</style>
